import React from 'react';
import { Modal, Box, Button, Label } from 'workspace-core-ui';
import { useOverlayTriggerState } from 'react-stately';
import { useAppDispatch } from '@hooks/redux-hooks';
import { logItem } from '@slices/loggingSlice';
import useTranslation from '@hooks/useTranslation';
import ReadMoreView, { ReadMoreViewProps } from './ReadMoreView';

const ReadMoreViewModalButton = (props: ReadMoreViewProps) => {
  const overlayState = useOverlayTriggerState({});
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <>
      <Button
        my={4}
        onPress={() => {
          dispatch(
            logItem({
              question_name: props.questionName,
              collection_name: 'events',
              event_type: 'click',
              target: `read_more_followup_open`,
            }),
          );
          overlayState.open();
        }}
        buttonSize="medium"
        variant="secondary"
      >
        <Label variant="l3"> {t('Result Why True')}</Label>
      </Button>
      <Modal
        title=""
        isOpen={overlayState.isOpen}
        onClose={() => {
          dispatch(
            logItem({
              question_name: props.questionName,
              collection_name: 'events',
              event_type: 'click',
              target: `read_more_followup_close`,
            }),
          );
          overlayState.close();
        }}
        isDismissable
        showClose
        closeLabel={t('Close')}
      >
        <Box maxWidth="800px">
          <ReadMoreView {...props} />
        </Box>
      </Modal>
    </>
  );
};

export default ReadMoreViewModalButton;
